<template>
	<div>
		<el-dialog draggable width="40%" top="5vh" :destroy-on-close="true" @close="Reset" v-model="outerVisible"
			:title="userid?'修改职务':'APP账号开通'">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="头像">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<img v-if="ruleForm.avatar" class="dialog-img" :src="ruleForm.avatar" />
							<img v-if="userid&&!ruleForm.avatar" class="dialog-img" src="@/assets/img/tx.png" />
							<p v-if="ruleForm.avatar" class="dele" @click="dele">删除</p>
							<el-upload :headers="Headers" :action="ActionUrl" :on-success="handleAvatarSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>

					<el-form-item label="选择类型">
						<span class="xx">*</span>
						<el-select clearable v-model="typeindex" @change="selectchange" placeholder="请选择类型">
							<!-- 管理员 -->
							<el-option v-if="$store.state.userinfo.roles==1" label="乡镇" value="0"></el-option>
							<el-option v-if="$store.state.userinfo.roles==1" label="单位" value="1"></el-option>
							<el-option v-if="$store.state.userinfo.roles==1" label="县政府督查室" value="2"></el-option>
							<el-option v-if="$store.state.userinfo.roles==1" label="县纪委/县委组织部" value="3"></el-option>
							<!-- 独立后台 -->
							<!-- 地区 -->
							<el-option v-if="$store.state.userinfo.roles==2&&$store.state.userinfo.type==0" label="乡镇"
								value="0"></el-option>
							<!-- 部门 -->
							<el-option v-if="$store.state.userinfo.roles==2&&$store.state.userinfo.type==1" label="单位"
								value="1"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="选择职务" v-if="typeindex">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.posts_id" placeholder="请选择职务">
							<el-option v-for="item in typelist" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="选择职位" v-if="ruleForm.posts_id&&typeindex<2">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.position_type" @change="typechange" placeholder="请选择职位">
							<el-option label="区域网格总长" value="2"></el-option>
							<el-option label="区域网格长" value="3"></el-option>
							<el-option label="网格长" value="4"></el-option>
							<el-option label="网格员" value="5"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="选择镇"
						v-if="(2<=ruleForm.position_type&&ruleForm.position_type<=5)&&typeindex==0">
						<span class="xx">*</span>
						<el-select :multiple="ruleForm.position_type==2?true:false" clearable v-model="ruleForm.town_id"
							@change="townchange" placeholder="请选择镇">
							<el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="选择村委会"
						v-if="(ruleForm.town_id&&(ruleForm.position_type==4||ruleForm.position_type==5))&&typeindex==0">
						<span class="xx">*</span>
						<el-select @change="villagechange" clearable v-model="ruleForm.village_id" placeholder="请选择村委会">
							<el-option v-for="item in village" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="管辖范围"
						v-if="(ruleForm.town_id&&ruleForm.village_id&&ruleForm.position_type==5)&&typeindex==0">
						<span class="xx">*</span>
						<el-select multiple @change="streetchange" clearable v-model="street_id" placeholder="请选择管辖范围">
							<el-option v-for="item in street" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>

					<!-- 部门 -->
					<el-form-item label="部门" v-if="typeindex==1&&ruleForm.position_type>1">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.county_id" @change="ybmchange" placeholder="请选择部门">
							<el-option v-for="item in department" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="二级部门" v-if="ruleForm.county_id&&ruleForm.position_type>=1&&typeindex==1">
						<span class="xx">*</span>
						<el-select :multiple="ruleForm.position_type==2?true:false" clearable v-model="ruleForm.town_id" @change="ebmchange" placeholder="请选择二级部门">
							<el-option v-for="item in erdepartment" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="三级部门" v-if="ruleForm.town_id&&ruleForm.position_type>=4&&typeindex==1">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.village_id" @change="sbmchange" placeholder="请选择三级部门">
							<el-option v-for="item in sandepartment" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="四级部门" v-if="ruleForm.village_id&&ruleForm.position_type==5&&typeindex==1">
						<span class="xx">*</span>
						<el-select clearable @change="sibmchange" v-model="ruleForm.street_id" placeholder="请选择四级部门">
							<el-option v-for="item in sidepartment" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="请选择上级" v-if="
					(ruleForm.position_type==3&&ruleForm.town_id)||
					(ruleForm.position_type==4&&ruleForm.town_id&&ruleForm.village_id)||
					(ruleForm.position_type==5&&ruleForm.town_id&&ruleForm.village_id&&(ruleForm.street_id||street_id.length>0))
					">
						<span class="xx">*</span>
						<el-select v-model="ruleForm.parent_id" placeholder="请选择上级">
							<el-option v-for="item in userlist" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>


					<el-form-item label="姓名">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
					</el-form-item>

					<el-form-item label="性别">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.sex" placeholder="请选择性别">
							<el-option label="男" :value="0"></el-option>
							<el-option label="女" :value="1"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="手机号码">
						<span class="xx">*</span>
						<el-input clearable type="number" v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
					</el-form-item>

					<el-form-item label="密码" v-if="!userid">
						<span class="xx">*</span>
						<el-input type="password" show-password clearable v-model="ruleForm.password"
							placeholder="请输入8到16位数字与字母组合密码"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" v-if="!userid">
						<span class="xx">*</span>
						<el-input type="password" show-password clearable v-model="ruleForm.qrpassword"
							placeholder="请输入确认密码">
						</el-input>
					</el-form-item>

				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" @click="SubmitForm" type="primary">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/user";
	export default {
		data() {
			return {
				typeindex: "",
				typelist: [],
				Headers: {
					authorization: '',
					appid:'',
					nonce:'',
					timestamp:'',
					sign:''
				},
				ActionUrl: '',
				outerVisible: false,
				ruleForm: {
					parent_id: '',
					name: '',
					avatar: '',
					phone: '',
					county_id: '',
					town_id: '',
					village_id: '',
					position_type: '',
					password: '',
					qrpassword: '',
					posts_id: '',
					street_id: '',
					sex: '',
					uid: ''
				},
				street_id: '',
				county: [],
				town: [],
				village: [],
				department: [],
				erdepartment: [],
				sandepartment: [],
				userlist: [],
				sidepartment: [],
				userid: ''
			}
		},
		mounted() {

			// 上转图片url
			this.Headers.authorization = sessionStorage.getItem('token')
			let param = JSON.parse(sessionStorage.getItem('param'))
			this.Headers.appid = param.appid
			this.Headers.nonce = param.nonce
			this.Headers.timestamp = param.timestamp
			this.Headers.sign = param.sign
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/System/FormUpload'
			
		},
		methods: {
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.avatar = res.data
			},
			// 获取职务
			getPosts() {
				this.$http.post(api.PostsList, {
						type: this.typeindex > 1 ? 1 : this.typeindex,
					})
					.then((res) => {
						if (res.code == 1) {
							this.typelist = res.data
						}
					})
			},
			// 选择类型
			selectchange(e) {
				this.ruleForm.posts_id = ""
				this.ruleForm.county_id = ""
				this.ruleForm.town_id = ""
				this.ruleForm.village_id = ""
				this.ruleForm.street_id = ""
				this.street_id = ""
				this.typelist = []

				// 判断选择
				if (this.typeindex == 2) {
					this.ruleForm.position_type = 0
				} else if (this.typeindex == 3) {
					this.ruleForm.position_type = 1
				} else {
					this.ruleForm.position_type = ""
				}

				this.getPosts()
				if (this.typeindex == 0) {
					this.getList()
				}

				this.gettown(this.ruleForm.county_id)
			},
			// 获取四级部门
			getsibmList(id) {
				this.$http.post(api.bmlists, {
						page: 1,
						limit: 9999,
						parent_id: id,
						type: 1
					})
					.then((res) => {
						if (res.code == 1) {
							this.sidepartment = res.data.list
						}
					})
			},
			// 四级部门选择
			sibmchange(e) {
				if (this.ruleForm.position_type == 5) {
					this.getUser(e)
				}
			},
			// 三级部门选择
			sbmchange(e) {
				this.sidepartment = []
				this.ruleForm.street_id = ""
				this.getsibmList(e)
				if (this.ruleForm.position_type == 4) {
					this.getUser(e)
				}
			},

			// 获取三级部门
			getsanbmList(id) {
				this.$http.post(api.bmlists, {
						page: 1,
						limit: 9999,
						parent_id: id,
						type: 1
					})
					.then((res) => {
						if (res.code == 1) {
							this.sandepartment = res.data.list
						}
					})
			},
			// 二级部门选择
			ebmchange(e) {
				this.ruleForm.village_id = ""
				this.ruleForm.street_id = ""
				this.street_id = ""
				this.getsanbmList(e)

				if (this.ruleForm.position_type == 3) {
					this.getUser(e)
				}
			},
			// 获取二级部门
			geterbmList(id) {
				this.$http.post(api.bmlists, {
						page: 1,
						limit: 9999,
						parent_id: id,
						type: 1
					})
					.then((res) => {
						if (res.code == 1) {
							this.erdepartment = res.data.list
						}
					})
			},
			// 一级部门选择
			ybmchange(e) {
				this.ruleForm.town_id = ""
				this.ruleForm.village_id = ""
				this.ruleForm.street_id = ""
				this.street_id = ""
				this.geterbmList(e)

			},
			// 获取部门
			getbmList() {
				this.$http.post(api.bmlists, {
						page: 1,
						limit: 9999,
						parent_id: 0,
						type: 1
					})
					.then((res) => {
						if (res.code == 1) {
							this.department = res.data.list
						}
					})
			},
			// 获取用户列表
			getUser(id) {
				this.$http.post(api.Userlist, {
						check_status: 1,
						position_type: this.ruleForm.position_type,
						district_id: this.street_id.length > 0 ? id.join(",") : id,
						type: this.typeindex > 1 ? 1 : this.typeindex
					})
					.then((res) => {
						if (res.code == 1) {
							this.userlist = res.data
						}
					})
			},
			// 职位选择
			typechange() {
				this.ruleForm.town_id = ""
				this.ruleForm.village_id = ""
				this.ruleForm.parent_id = ""
				this.ruleForm.street_id = ""
				this.street_id = ""
			},
			// 清空数据
			Reset() {
				this.typeindex = ""
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
			},
			// 选择街道
			streetchange(e) {
				console.log(e)
				if (this.ruleForm.position_type == 5) {
					this.getUser(e)
				}
			},
			// 获取街道
			getstreet(id) {
				this.$http.post(api.getList, {
						page: 1,
						limit: 9999,
						parent_id: id,
						type: 0
					})
					.then((res) => {
						if (res.code == 1) {
							this.street = res.data.list
						}
					})
			},
			// 选择村委会
			villagechange(e) {
				this.getstreet(e)
				this.ruleForm.parent_id = ""
				if (this.ruleForm.position_type == 4) {
					this.getUser(e)
				}
			},
			// 获取区
			getvillage(id) {
				this.$http.post(api.getList, {
						page: 1,
						limit: 9999,
						parent_id: id,
						type: 0
					})
					.then((res) => {
						if (res.code == 1) {
							this.village = res.data.list
						}
					})
			},
			// 选择镇
			townchange(e) {
				this.ruleForm.parent_id = ""
				this.ruleForm.village_id = ""
				if (this.ruleForm.position_type != 2) {
					this.getvillage(e)
				}
				if (this.ruleForm.position_type == 3) {
					this.getUser(e)
				}
			},
			// 获取镇
			gettown(id) {
				this.$http.post(api.getList, {
						page: 1,
						limit: 9999,
						parent_id: id,
						type: 0
					})
					.then((res) => {
						if (res.code == 1) {
							this.town = res.data.list
						}
					})
			},
			// 获取县
			getList() {
				this.$http.post(api.getList, {
						page: 1,
						limit: 9999,
						parent_id: 0,
						type: 0
					})
					.then((res) => {
						if (res.code == 1) {
							this.county = res.data.list
							if (this.$store.state.userinfo.type == 0) {
								this.ruleForm.county_id = res.data.list[0].id
							}
							this.gettown(this.ruleForm.county_id)
						}
					})
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.userread, {
						id: this.userid
					})
					.then((res) => {
						if (res.code == 1) {
							this.ruleForm.avatar = res.data.avatar
							this.ruleForm.name = res.data.name
							this.ruleForm.phone = res.data.phone
							this.ruleForm.sex = res.data.sex
						}
					})
			},
			// 显示
			show(id) {
				// 乡镇
				if (this.$store.state.userinfo.roles == 2 && this.$store.state.userinfo.type == 0) {
					this.typeindex = "0"
					this.getPosts()
				}
				// 部门
				if (this.$store.state.userinfo.roles == 2 && this.$store.state.userinfo.type == 1) {
					this.typeindex = "1"
					this.getPosts()
				}
				if (id) {
					this.userid = id
					this.ruleForm.uid = id
					this.getinfo()
				}
				this.getList()
				this.getbmList()
				this.outerVisible = true

			},
			//新增/编辑
			SubmitForm() {
				if (this.street_id.length > 0) {
					this.ruleForm.street_id = this.street_id.join(",")
				}
				if (this.typeindex === '' && this.$store.state.userinfo.roles == 1) {
					ElMessage({
						message: '请选择选择类型',
						type: 'warning',
					})
				} else if (this.ruleForm.posts_id === '') {
					ElMessage({
						message: '请选择职务',
						type: 'warning',
					})
				} else if (this.ruleForm.position_type === '' && this.typeindex < 2) {
					ElMessage({
						message: '请选择职位',
						type: 'warning',
					})
				}
				// 乡镇
				else if (this.ruleForm.position_type > 1 && this.typeindex == 0 && this.ruleForm.town_id == "") {
					ElMessage({
						message: '请选择镇',
						type: 'warning',
					})
				} else if (this.ruleForm.position_type > 3 && this.typeindex == 0 && this.ruleForm.village_id == "") {
					ElMessage({
						message: '请选择村委会',
						type: 'warning',
					})
				} else if (this.ruleForm.position_type == 5 && this.typeindex == 0 && this.ruleForm.street_id == "") {
					ElMessage({
						message: '请选择管辖范围',
						type: 'warning',
					})
				}
				// 部门
				else if (this.typeindex == 1 && this.ruleForm.position_type > 1 && this.ruleForm.county_id == "") {
					ElMessage({
						message: '请选择部门',
						type: 'warning',
					})
				} else if (this.typeindex == 1 && this.ruleForm.position_type > 1 && this.ruleForm.town_id == "") {
					ElMessage({
						message: '请选择二级部门',
						type: 'warning',
					})
				} else if (this.typeindex == 1 && this.ruleForm.position_type > 3 && this.ruleForm.village_id == "") {
					ElMessage({
						message: '请选择三级部门',
						type: 'warning',
					})
				} else if (this.typeindex == 1 && this.ruleForm.position_type > 4 && this.ruleForm.street_id == "") {
					ElMessage({
						message: '请选择四级部门',
						type: 'warning',
					})
				} else if (this.ruleForm.position_type > 2 && this.ruleForm.parent_id == "") {
					ElMessage({
						message: '请选择上级',
						type: 'warning',
					})
				} else if (this.ruleForm.name === "") {
					ElMessage({
						message: '请输入姓名',
						type: 'warning',
					})
				} else if (this.ruleForm.sex === "") {
					ElMessage({
						message: '请选择性别',
						type: 'warning',
					})
				} else if (this.ruleForm.phone === "") {
					ElMessage({
						message: '请输入手机号码',
						type: 'warning',
					})
				} else if (!(/^1[3456789]\d{9}$/.test(this.ruleForm.phone)) && this.ruleForm.phone) {
					ElMessage({
						message: '请输入正确的号码格式',
						type: 'warning',
					})
				} else if (this.ruleForm.password === '' && this.userid === '') {
					ElMessage({
						message: '请输入密码',
						type: 'warning',
					})
				} else if ((!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(this.ruleForm.password)) && this
						.userid === '')) {
					ElMessage({
						message: '请输入8到16位数字与字母组合密码',
						type: 'warning',
					})

				} else if (this.ruleForm.qrpassword === '' && this.userid === '') {
					ElMessage({
						message: '请输入确认密码',
						type: 'warning',
					})
				} else if ((this.ruleForm.qrpassword != this.ruleForm.password && this.userid === '')) {
					ElMessage({
						message: '密码不一致',
						type: 'warning',
					})
				} else {
					if (this.ruleForm.position_type === '2') {
						this.ruleForm.town_id = this.ruleForm.town_id.join(",")
					}
					this.$http.post(api.create, this.ruleForm)
						.then((res) => {
							if (res.code == 1) {
								ElMessage({
									type: 'success',
									message: this.userid ? '修改成功' : '新增成功',
								})
								this.outerVisible = false
								if (this.userid) {
									// 刷新列表
									this.$emit('SubmitForm')
								}

							}
						})
				}
			}
		}
	}
</script>

<style scoped>
</style>
